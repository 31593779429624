<template>
  <section class="w-full">
    <PageHeader
      :heading="$t('xpbx.pages.heading.create_domain')"
      :showButton="false"
    />
    <div
      class="m-5 pb-6 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-md md:w-1/2"
    >
      <!-- Domain -->
      <div class="px-6 py-2 text-left">
        <FormInput
          class="form-group pb-3"
          name="number"
          v-model="input.domain"
          :placeholder="$t('xpbx.placeholders.domain')"
          :footerLabel="$t('xpbx.labels.domain')"
          :errors="errors.domain"
        />
      </div>
      <!-- Submit button -->
      <div class="flex flex-row-reverse items-end flew-wrap mt-6 px-6 py-2">
        <button
          :disabled="savingProfile"
          class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
          @click.prevent="saveDomain('personal')"
          :class="{ 'cursor-not-allowed': savingProfile }"
        >
          {{ $t("button.label.save-changes") }}
          <font-awesome-icon
            icon="circle-notch"
            class="fa-spin"
            v-show="savingProfile"
          />
        </button>
        <button
          class="bg-dark-gray text-white p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md mr-4"
          @click="$router.go(-1)"
        >
          {{ $t("button.label.cancel") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import { validateCreateDomain } from "@/composables/auth/createDomainValidations";
import useDomains from "@/modules/xpbx/composables/useDomains";
import validator from "@/composables/auth/validator";

export default {
  name: "CreateDomain",
  components: {
    PageHeader,
  },
  setup() {
    const { errors } = validator();
    const savingProfile = ref(false);
    const input = ref({
      domain: "",
    });

    const { createDomain } = useDomains();

    const saveDomain = async (type) => {
      const isValid = validateCreateDomain(input.value);

      if (isValid) {
        await createDomain(input.value);
      }
    };

    return {
      input,
      errors,
      savingProfile,
      saveDomain,
    };
  },
  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>
